<template>
  <div class="page-title-icon clear">
    <!-- <img class="m-right-sm title-img" :src="iconUrl" alt /> -->

    <div class="content">
      <!--<b class="font-heading-2 title">{{ title }}</b> -->
      <div class="font-color-sub">{{ sub }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },

  props: {
    title: String,
    sub: String,
    iconUrl: {
      type: String,
      default: require('@/assets/images/page-title-icon-1.png')
    }
  },

  components: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.page-title-icon {
  .title-img {
    float: left;
    height: 30px;
  }

  .content {
    float: left;
    margin-top: -6px;

    .title {
      display: block;
      margin-bottom: 4px;
    }
  }
}
</style>
