<template>
  <div class="m-bottom-lg">
    <router-link :to="`/allmusic?labels=${item.label_id}`">
      <b class="m-bottom-xs inline-block font-color-base">{{item.name}}</b>
    </router-link>

    <div v-if="item.child_label && item.child_label.length > 0" class="child-box">
      <router-link
        class="font-color-sub m-bottom-xs block"
        v-for="cItem in item.child_label"
        :to="`/allmusic?labels=${item.label_id}`"
        :key="cItem.label_id"
      >{{cItem.name}}</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },

    props: {
      item: {
        type: Object,
        default() {
          return {};
        }
      },
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
</style>
