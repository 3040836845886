<template>
  <div class="label-detail container">
    <a-row class="m-bottom-md" :gutter="{xs: 10, sm: 10, md: 20}">
      <a-col
        v-for="(item, index) in labelPic"
        class="m-bottom-lg"
        :xs="12"
        :sm="12"
        :md="4"
        :key="index"
      >
        <router-link
          class="img-all-box m-bottom-sm block"
          :to="`/allmusic?labels=${item.label_id}`"
        >
          <ng-image class="w-100 img" :src="item.label_pic" alt></ng-image>
        </router-link>
        <b class="ellipsis inline-block">{{item.name}}</b>
        <!-- <div class="ellipsis">
          <a-tag>{{item.name}}</a-tag>
        </div>-->
      </a-col>
    </a-row>

    <div class="bottom-content">
      <page-title-icon class="m-bottom-md" :title="bottomTitle"></page-title-icon>

      <a-row>
        <a-col
          v-for="(item, index) in outerColumns"
          class="label-list"
          :xs="Math.floor(24 / outerColumns)"
          :sm="Math.floor(24 / outerColumns)"
          :key="index"
        >
          <a-row>
            <a-col
              v-for="(pitem, pindex) in labelsList.filter((i, cindex) => cindex % outerColumns === index)"
              :key="pindex"
              :xs="24"
              :sm="24"
              :md="8"
            >
              <template v-if="pitem && pitem.length > 0">
                <template v-for="item in pitem">
                  <label-row-item :item="item" :key="item.label_id"></label-row-item>
                </template>
              </template>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
  import PageTitleIcon from "@/pages/components/common/PageTitleIcon.vue";
  import LabelRowItem from './components/LabelRowItem.vue'

  const cateDir = {
    style: 'style',
    mood: 'mood'
  }

  const DefaultArr = (new Array(12)).fill('').map(() => ({}));

  export default {
    data() {
      return {
        labelPic: DefaultArr,
        labelsList: [],
        outerColumns: 2
      }
    },

    computed: {
      isStyle() {
        const params = this.$route.params;
        const cate_id = params.cate_id;

        return cate_id === cateDir.style;
      },
      request() {
        return this.isStyle ? this.$axios.Music_Fine_Style : this.$axios.Music_Fine_Mood;
      },

      bottomTitle() {
        return this.isStyle ? '全部风格' : '全部情绪'
      }
    },

    mounted() {
      this.getData();
    },

    components: {
      PageTitleIcon,
      LabelRowItem
    },
    methods: {
      getData() {
        this.$store.commit('changeContentLoading', true);

        this.request().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let dir = data.data ? data.data : [];
            let {label_pic = [], label_list = []} = dir;

            this.labelPic = label_pic;
            this.labelsList = label_list;
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label-detail {
    margin-top: 40px;
  }

  .child-box {
    padding-left: 5px;
  }
</style>
